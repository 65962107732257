import _core from "./lib/core";
import _zip from "./lib/plugins/zip";
import _tar from "./lib/plugins/tar";
import _json from "./lib/plugins/json";
var exports = {};

/**
 * Archiver Vending
 *
 * @ignore
 * @license [MIT]{@link https://github.com/archiverjs/node-archiver/blob/master/LICENSE}
 * @copyright (c) 2012-2014 Chris Talkington, contributors.
 */
var Archiver = _core;
var formats = {};
/**
 * Dispenses a new Archiver instance.
 *
 * @constructor
 * @param  {String} format The archive format to use.
 * @param  {Object} options See [Archiver]{@link Archiver}
 * @return {Archiver}
 */

var vending = function (format, options) {
  return vending.create(format, options);
};
/**
 * Creates a new Archiver instance.
 *
 * @param  {String} format The archive format to use.
 * @param  {Object} options See [Archiver]{@link Archiver}
 * @return {Archiver}
 */


vending.create = function (format, options) {
  if (formats[format]) {
    var instance = new Archiver(format, options);
    instance.setFormat(format);
    instance.setModule(new formats[format](options));
    return instance;
  } else {
    throw new Error("create(" + format + "): format not registered");
  }
};
/**
 * Registers a format for use with archiver.
 *
 * @param  {String} format The name of the format.
 * @param  {Function} module The function for archiver to interact with.
 * @return void
 */


vending.registerFormat = function (format, module) {
  if (formats[format]) {
    throw new Error("register(" + format + "): format already registered");
  }

  if (typeof module !== "function") {
    throw new Error("register(" + format + "): format module invalid");
  }

  if (typeof module.prototype.append !== "function" || typeof module.prototype.finalize !== "function") {
    throw new Error("register(" + format + "): format module missing methods");
  }

  formats[format] = module;
};

vending.registerFormat("zip", _zip);
vending.registerFormat("tar", _tar);
vending.registerFormat("json", _json);
exports = vending;
export default exports;