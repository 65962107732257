import _util from "util";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * Archiver Core
 *
 * @ignore
 * @license [MIT]{@link https://github.com/archiverjs/node-archiver/blob/master/LICENSE}
 * @copyright (c) 2012-2014 Chris Talkington, contributors.
 */
var util = _util;
const ERROR_CODES = {
  "ABORTED": "archive was aborted",
  "DIRECTORYDIRPATHREQUIRED": "diretory dirpath argument must be a non-empty string value",
  "DIRECTORYFUNCTIONINVALIDDATA": "invalid data returned by directory custom data function",
  "ENTRYNAMEREQUIRED": "entry name must be a non-empty string value",
  "FILEFILEPATHREQUIRED": "file filepath argument must be a non-empty string value",
  "FINALIZING": "archive already finalizing",
  "QUEUECLOSED": "queue closed",
  "NOENDMETHOD": "no suitable finalize/end method defined by module",
  "DIRECTORYNOTSUPPORTED": "support for directory entries not defined by module",
  "FORMATSET": "archive format already set",
  "INPUTSTEAMBUFFERREQUIRED": "input source must be valid Stream or Buffer instance",
  "MODULESET": "module already set",
  "SYMLINKNOTSUPPORTED": "support for symlink entries not defined by module",
  "SYMLINKFILEPATHREQUIRED": "symlink filepath argument must be a non-empty string value",
  "SYMLINKTARGETREQUIRED": "symlink target argument must be a non-empty string value",
  "ENTRYNOTSUPPORTED": "entry not supported"
};

function ArchiverError(code, data) {
  Error.captureStackTrace(this || _global, (this || _global).constructor); //this.name = this.constructor.name;

  (this || _global).message = ERROR_CODES[code] || code;
  (this || _global).code = code;
  (this || _global).data = data;
}

util.inherits(ArchiverError, Error);
exports = exports = ArchiverError;
export default exports;