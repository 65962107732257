import _util from "util";
import _readableStream from "readable-stream";
import _bufferCrc from "buffer-crc32";
import _archiverUtils from "archiver-utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * JSON Format Plugin
 *
 * @module plugins/json
 * @license [MIT]{@link https://github.com/archiverjs/node-archiver/blob/master/LICENSE}
 * @copyright (c) 2012-2014 Chris Talkington, contributors.
 */
var inherits = _util.inherits;
var Transform = _readableStream.Transform;
var crc32 = _bufferCrc;
var util = _archiverUtils;
/**
 * @constructor
 * @param {(JsonOptions|TransformOptions)} options
 */

var Json = function (options) {
  if (!((this || _global) instanceof Json)) {
    return new Json(options);
  }

  options = (this || _global).options = util.defaults(options, {});
  Transform.call(this || _global, options);
  (this || _global).supports = {
    directory: true,
    symlink: true
  };
  (this || _global).files = [];
};

inherits(Json, Transform);
/**
 * [_transform description]
 *
 * @private
 * @param  {Buffer}   chunk
 * @param  {String}   encoding
 * @param  {Function} callback
 * @return void
 */

Json.prototype._transform = function (chunk, encoding, callback) {
  callback(null, chunk);
};
/**
 * [_writeStringified description]
 *
 * @private
 * @return void
 */


Json.prototype._writeStringified = function () {
  var fileString = JSON.stringify((this || _global).files);
  this.write(fileString);
};
/**
 * [append description]
 *
 * @param  {(Buffer|Stream)}   source
 * @param  {EntryData}   data
 * @param  {Function} callback
 * @return void
 */


Json.prototype.append = function (source, data, callback) {
  var self = this || _global;
  data.crc32 = 0;

  function onend(err, sourceBuffer) {
    if (err) {
      callback(err);
      return;
    }

    data.size = sourceBuffer.length || 0;
    data.crc32 = crc32.unsigned(sourceBuffer);
    self.files.push(data);
    callback(null, data);
  }

  if (data.sourceType === "buffer") {
    onend(null, source);
  } else if (data.sourceType === "stream") {
    util.collectStream(source, onend);
  }
};
/**
 * [finalize description]
 *
 * @return void
 */


Json.prototype.finalize = function () {
  this._writeStringified();

  this.end();
};

exports = Json;
/**
 * @typedef {Object} JsonOptions
 * @global
 */

export default exports;