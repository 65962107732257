import _zlib from "zlib";
import _tarStream from "tar-stream";
import _archiverUtils from "archiver-utils";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * TAR Format Plugin
 *
 * @module plugins/tar
 * @license [MIT]{@link https://github.com/archiverjs/node-archiver/blob/master/LICENSE}
 * @copyright (c) 2012-2014 Chris Talkington, contributors.
 */
var zlib = _zlib;
var engine = _tarStream;
var util = _archiverUtils;
/**
 * @constructor
 * @param {TarOptions} options
 */

var Tar = function (options) {
  if (!((this || _global) instanceof Tar)) {
    return new Tar(options);
  }

  options = (this || _global).options = util.defaults(options, {
    gzip: false
  });

  if (typeof options.gzipOptions !== "object") {
    options.gzipOptions = {};
  }

  (this || _global).supports = {
    directory: true,
    symlink: true
  };
  (this || _global).engine = engine.pack(options);
  (this || _global).compressor = false;

  if (options.gzip) {
    (this || _global).compressor = zlib.createGzip(options.gzipOptions);

    (this || _global).compressor.on("error", (this || _global)._onCompressorError.bind(this || _global));
  }
};
/**
 * [_onCompressorError description]
 *
 * @private
 * @param  {Error} err
 * @return void
 */


Tar.prototype._onCompressorError = function (err) {
  (this || _global).engine.emit("error", err);
};
/**
 * [append description]
 *
 * @param  {(Buffer|Stream)} source
 * @param  {TarEntryData} data
 * @param  {Function} callback
 * @return void
 */


Tar.prototype.append = function (source, data, callback) {
  var self = this || _global;
  data.mtime = data.date;

  function append(err, sourceBuffer) {
    if (err) {
      callback(err);
      return;
    }

    self.engine.entry(data, sourceBuffer, function (err) {
      callback(err, data);
    });
  }

  if (data.sourceType === "buffer") {
    append(null, source);
  } else if (data.sourceType === "stream" && data.stats) {
    data.size = data.stats.size;
    var entry = self.engine.entry(data, function (err) {
      callback(err, data);
    });
    source.pipe(entry);
  } else if (data.sourceType === "stream") {
    util.collectStream(source, append);
  }
};
/**
 * [finalize description]
 *
 * @return void
 */


Tar.prototype.finalize = function () {
  (this || _global).engine.finalize();
};
/**
 * [on description]
 *
 * @return this.engine
 */


Tar.prototype.on = function () {
  return (this || _global).engine.on.apply((this || _global).engine, arguments);
};
/**
 * [pipe description]
 *
 * @param  {String} destination
 * @param  {Object} options
 * @return this.engine
 */


Tar.prototype.pipe = function (destination, options) {
  if ((this || _global).compressor) {
    return (this || _global).engine.pipe.apply((this || _global).engine, [(this || _global).compressor]).pipe(destination, options);
  } else {
    return (this || _global).engine.pipe.apply((this || _global).engine, arguments);
  }
};
/**
 * [unpipe description]
 *
 * @return this.engine
 */


Tar.prototype.unpipe = function () {
  if ((this || _global).compressor) {
    return (this || _global).compressor.unpipe.apply((this || _global).compressor, arguments);
  } else {
    return (this || _global).engine.unpipe.apply((this || _global).engine, arguments);
  }
};

exports = Tar;
/**
 * @typedef {Object} TarOptions
 * @global
 * @property {Boolean} [gzip=false] Compress the tar archive using gzip.
 * @property {Object} [gzipOptions] Passed to [zlib]{@link https://nodejs.org/api/zlib.html#zlib_class_options}
 * to control compression.
 * @property {*} [*] See [tar-stream]{@link https://github.com/mafintosh/tar-stream} documentation for additional properties.
 */

/**
 * @typedef {Object} TarEntryData
 * @global
 * @property {String} name Sets the entry name including internal path.
 * @property {(String|Date)} [date=NOW()] Sets the entry date.
 * @property {Number} [mode=D:0755/F:0644] Sets the entry permissions.
 * @property {String} [prefix] Sets a path prefix for the entry name. Useful
 * when working with methods like `directory` or `glob`.
 * @property {fs.Stats} [stats] Sets the fs stat data for this entry allowing
 * for reduction of fs stat calls when stat data is already known.
 */

/**
 * TarStream Module
 * @external TarStream
 * @see {@link https://github.com/mafintosh/tar-stream}
 */

export default exports;